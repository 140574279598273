import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export const PlansModal = () => {
  const stripe = useStripe();
  const elements = useElements();

  function handleSubmitPlanForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // send(actionMap.submit_form);

    // const cardElement = elements.getElement(CardElement);

    // const { token, error } = await stripe.createToken(cardElement);

    // onSubmit(context.values.plan, { token, error });
  }

  return (
    <div className="mt-2 px-7 py-3">
      <form className="flex flex-col" onSubmit={handleSubmitPlanForm}>
        <label className="flex flex-col my-2">
          <span>Name on Card</span>
          <input autoFocus type="text" onChange={(e) => {}} />
        </label>
        <label className="my-2">
          <span className="pr-3">Confirm the beta plan</span>
          <input type="checkbox" onChange={(e) => {}} />
        </label>
        <label className="my-2">
          <span className="pr-3">Pay Annually</span>
          <input type="checkbox" onChange={(e) => {}} />
        </label>
        <label className="my-2">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                },
              },
            }}
            onChange={(e) => {}}
          />
        </label>
        <div className="flex items-center px-4 py-3">
          <button
            disabled={!stripe || !elements}
            className="mx-4 px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
          >
            Update Plan
          </button>
          <button
            className="mx-4 px-4 py-2 border-2 border-grey-500 hover:border-transparent bg-transparent text-gray-500 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={(e) => {}}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
