import React from "react";
import { Link } from "react-router-dom";

import { eventMap, useHoldFiles } from "../../context/holdFilesContext";
import { useTotals } from "../../context/totalsContext";
import InterestsList from "../InterestsList";
import ActionPlusButton from "../buttons/ActionPlusButton";
import InputModal from "../InputModal";
import useKeypress from "../../hooks/useKeypress";
import ModalHeader from "../ModalHeader";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { useTitles } from "../../context/titlesContext";
import ReactSelect from "react-select";
import useShopDetails from "../../hooks/useShopDetails";
import Button from "../buttons/Button";
import { useBundles } from "../../machines/bundlesMachine";
import { TCustomer } from "../../types/customer";
import { THoldFile } from "../../types/holdFile";
import { TTitle } from "../../types/titles";
import { TBundle } from "../../types/bundle";

const CustomerInterests = ({ customer }: { customer: TCustomer }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [notesValue, setNotesValue] = React.useState("");
  const [editItem, setEditItem] = React.useState<Partial<THoldFile>>({});
  const [selectedOption, setSelectedOption] = React.useState<Partial<TTitle>>(
    {}
  );
  const { bundles } = useBundles();
  const { titles } = useTitles();
  const { shop } = useShopDetails();
  const {
    customersHoldFiles,
    loaded,
    send,
    editing,
    submitting,
    newHoldFile,
    handleHideHoldFiles,
  } = useHoldFiles();
  const { handleRefreshTotals } = useTotals();
  const compoundId = `HOLDFILE#SHOP#${shop}${customer.sk}`;
  const list = customersHoldFiles[compoundId] || [];
  const interestsList = list.sort((a, b) => (a.active < b.active ? 1 : -1));

  const options = titles?.reduce((acc, title) => {
    if (!title.active) {
      return acc;
    }

    return [
      ...acc,
      {
        ...title,
        value: title.sk,
        label: title.title,
      },
    ];
  }, [] as TTitle[]);

  useKeypress("Escape", () => {
    handleHideHoldFiles();
    setIsEditing(false);
  });

  React.useEffect(() => {
    if (shop && customer) {
      send({ type: "view-customer", customer, shop });
    }
  }, [customer, send, shop]);

  const handleModalClose = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const closeIds = ["modal", "close-icon"];
    //@ts-ignore
    if (closeIds.includes(event.target.id)) {
      handleHideHoldFiles();
    }
  };

  const handleHoldFileSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    //@ts-ignore
    const submitAndClose = e.nativeEvent.submitter.id === "submit-and-close";

    e.preventDefault();
    if (submitAndClose) {
      send({ type: "submit-and-close", customer, shop: shop! });
    } else {
      send({ type: "submit", customer, shop: shop! });
    }
    handleRefreshTotals();
    setSelectedOption({});
  };

  const handleCloseEditModal = () => {
    setIsEditing(false);
    setEditItem({});
  };

  const handleFormSubmit = () => {
    send({
      type: "edit-hold-file",
      note: notesValue,
      item: editItem as THoldFile,
    });
    setIsEditing(false);
    setEditItem({});
    setNotesValue("");
  };

  const handleEdit = (item: THoldFile) => {
    setNotesValue(item.notes || "");
    setIsEditing(true);
    setEditItem(item);
  };

  return (
    <>
      <h3 className="hidden print:block">
        {customer.firstName} {customer.lastName}
      </h3>
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <div>
            <header className="flex justify-between text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
              Hold File <span className="mr-24 pr-2">Notes</span>
            </header>
            <InterestsList
              loaded={loaded || editing}
              interestsList={interestsList}
              //@ts-ignore
              send={send}
              sendType={{ type: "delete", customer }}
              refreshFunction={handleRefreshTotals}
              handleEdit={handleEdit}
            />
          </div>
          <div className="flex justify-between px-2 pt-2 print:hidden">
            <button
              className="text-sm text-green-600"
              onClick={() => window.print()}
            >
              Print
            </button>
            <ActionPlusButton
              disabled={!loaded}
              send={send}
              sendType={eventMap.edit}
            />
          </div>
        </div>
        {(editing || submitting) && (
          <InputModal onCloseModal={handleModalClose}>
            <ModalHeader>{`Manage ${capitalizeFirstLetter(
              customer.firstName
            )}'s hold file`}</ModalHeader>
            <div className="md:flex">
              <article className="flex-1 border-2 m-4">
                <h3 className="bg-gray-200 text-lg bottom-2 p-2">Hold File</h3>
                <div className="p-2">
                  <p className="text-sm">
                    Choose a book from the drop down. Apply optional attributes
                    like:
                  </p>
                  <ul>
                    <li className="pl-4"> - A/B Cover Preference</li>
                  </ul>
                </div>
                <h3 className="bg-gray-200 text-lg bottom-2 p-2">Notes</h3>
                <div className="p-2">
                  <p className="text-sm">
                    Add optional contextual information about a customer.
                  </p>
                </div>
                <p className="text-sm bg-gray-200 bottom-2 p-2">
                  We keep totals of your customers hold files. Totals are{" "}
                  <Link className="underline" to="/">
                    available here
                  </Link>{" "}
                  for you to reference.
                </p>
              </article>
              <section className="flex-2 border-2 m-4 p-4">
                <h4 className="text-gray-500 mb-6">
                  Add a book to the hold file
                </h4>
                <form onSubmit={handleHoldFileSubmission}>
                  <label
                    htmlFor="firstName"
                    className="flex flex-1 flex-col my-2 md:mr-2"
                  >
                    <span>Book</span>
                    {/* React Select needs some styles with tailwind forms */}
                    <ReactSelect
                      styles={{
                        // @ts-expect-error
                        input: (base) => ({
                          ...base,
                          "input:focus": {
                            boxShadow: "none",
                          },
                        }),
                      }}
                      defaultValue={selectedOption}
                      value={selectedOption}
                      options={options}
                      // @ts-expect-error
                      onChange={(newValue: TTitle) => {
                        send({
                          type: "change_form_value",
                          value: { holdFile: newValue },
                        });
                        setSelectedOption(newValue);
                      }}
                    />
                  </label>
                  <label
                    htmlFor="notes"
                    className="flex flex-1 flex-col my-2 md:mr-2 pb-4"
                  >
                    <span>Notes</span>
                    <input
                      type="text"
                      name="notes"
                      placeholder="Saga Compendium"
                      value={newHoldFile.notes}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        send({
                          type: "change_form_value",
                          value: { [e.target.name]: e.target.value },
                        });
                      }}
                    />
                  </label>
                  <Button
                    id="submit-and-close"
                    variant="secondary"
                    icon="plus"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="inverted-create"
                    icon="plus"
                    type="submit"
                    className="ml-4"
                  >
                    Submit and add another
                  </Button>
                </form>
                <hr className="m-4" />
                <form>
                  <ReactSelect
                    placeholder="Select a bundle to apply to this customers file"
                    // @ts-ignore
                    onChange={(item: TBundle) => {
                      send({
                        type: "submit-bundle",
                        bundle: item,
                        shop: shop!,
                        customerId: customer.customerId,
                      });
                      handleRefreshTotals();
                      handleHideHoldFiles();
                    }}
                    options={bundles.map((bundle) => {
                      return {
                        ...bundle,
                        value: bundle.sk,
                        label: bundle.bundleName,
                      };
                    })}
                  />
                </form>
              </section>
            </div>
          </InputModal>
        )}
        {isEditing && (
          <InputModal onCloseModal={handleCloseEditModal}>
            <ModalHeader className="text-sm">{`Add a note to ${editItem?.holdFile}`}</ModalHeader>
            <div>
              <label htmlFor="note">
                <div className="sm:flex justify-center items-start">
                  Note:
                  <textarea
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    autoFocus
                    value={notesValue || ""}
                    onChange={(e) => setNotesValue(e.target.value)}
                    name="note"
                    id="note"
                    className="ml-4 w-80 min-h-100"
                    rows={editItem?.notes ? editItem?.notes.length / 30 + 1 : 3}
                  />
                  <div className="mt-4 sm:mt-0 text-right sm:text-left">
                    <Button
                      variant="secondary"
                      className="ml-4"
                      type="button"
                      onClick={() => handleFormSubmit()}
                    >
                      Create Note
                    </Button>
                  </div>
                </div>
              </label>
            </div>
          </InputModal>
        )}
      </div>
    </>
  );
};

export default CustomerInterests;
